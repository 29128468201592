import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from "../components/layout/layout"
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import "./frontpage.scss"

export const IndexPageTemplate = ({
  image,
  title,
  content,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;

  return(
    <div className="page frontpage">
      <div className="image-text-wrapper">
        <PreviewCompatibleImage
          imageInfo={{
            image: image,
            alt: title,
          }}
        />
        <div className="body">
          <h1>{title}</h1>
          <PageContent content={content} />
        </div>
      </div>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  body: PropTypes.string,
  contentComponent: PropTypes.func,
}

const IndexPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <Layout>
     <main>
       <section>
        <IndexPageTemplate
          image={frontmatter.image}
          title={frontmatter.title}
          content={html}
          contentComponent={HTMLContent}
        />
        </section>
      </main>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
